<template>
  <div class="mb-4" v-if="isPermission && isEditMember">
    <h6 class="pl-2 font-weight-bold">
      {{ $t('contact') }}
      <strong class="text-danger">{{ contactAlert }}</strong>
    </h6>
    <CCard>
      <CCardBody class="pb-0">
        <table style="width:100%" class="table">
          <tr>
            <td style="width:15%">
              {{ $t('firstname') }}
            </td>
            <td>
              <input
                id="editAddressFirstname"
                style="border:none"
                type="text"
                class="form-control"
                placeholder="กรอกชื่อ"
                v-model="name"
              />
            </td>
          </tr>
          <!-- <tr>
            <td>
              {{ $t('lastname') }}
            </td>
            <td>
              <input
                id="editAddressLastname"
                style="border:none"
                type="text"
                class="form-control"
                placeholder="กรอกนามสกุล"
                v-model="lastname"
              />
            </td>
          </tr> -->
          <tr>
            <td>{{ $t('taxID') }}</td>
            <td>
              <input
                id="editAddressTaxId"
                v-model="taxId"
                style="border:none"
                class="form-control"
                type="text"
                placeholder="กรอกเลขประจำตัวประชาชน/เลขนิติบุคคล"
              />
            </td>
          </tr>
          <tr style="border-bottom: 1px solid #ECECEC;">
            <td>
              {{ $t('tel') }}
            </td>
            <td>
              <input
                id="editAddressPhoneNumber"
                v-model="phoneNumber"
                style="border:none"
                type="text"
                class="form-control"
                placeholder="กรอกเบอร์โทรศัพท์"
              />
            </td>
          </tr>
        </table>
      </CCardBody>
    </CCard>
    <h6 class="pl-2 font-weight-bold">
      {{ $t('address') }}
      <strong class="text-danger">{{ addressAlert }}</strong>
    </h6>
    <CCard>
      <CCardBody class="pb-0">
        <table class="table">
          <!-- <tr>
            <td style="width:15%">
              {{ $t('province') }}
            </td>
            <td>
              <input
                id="editAddressProvince"
                v-model="province"
                type="text"
                class="form-control"
                style="border:none"
                :placeholder="$t('enterProvince')"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('district') }}</td>
            <td>
              <input
                id="editAddressDistrict"
                v-model="amphoe"
                type="text"
                class="form-control"
                style="border:none"
                :placeholder="$t('enterAmphoe')"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('subdistrict') }}</td>
            <td>
              <input
                id="editAddressSubdistrict"
                v-model="tambon"
                type="text"
                class="form-control"
                style="border:none"
                :placeholder="$t('enterTambon')"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('postalCode') }}</td>
            <td>
              <input
                id="editAddressPostalCode"
                v-model="postalCode"
                type="text"
                class="form-control"
                style="border:none"
                placeholder="กรอกรหัสไปรษณีย์"
              />
            </td>
          </tr> -->
          <tr style="border-bottom: 1px solid #ECECEC;">
            <td class="text-dark" style="width: 15%">{{ $t('address') }}</td>
            <td>
              <textarea
                id="editAddressAddressNumber"
                v-model="address"
                type="text"
                class="form-control"
                style="border:none"
                placeholder="กรอกที่อยู่"
              />
            </td>
          </tr>
        </table>
      </CCardBody>
    </CCard>
    <h6 class="pl-2 font-weight-bold">{{ $t('setting') }}</h6>
    <CCard>
      <CCardBody class="pb-0">
        <table class="table">
          <tr>
            <td>{{ $t('setAsDefaultAddress') }}</td>
            <td style="width:5%">
              <CSwitch
                id="editAddressSetAsDefault"
                color="success"
                v-model="isDefault"
                :checked.sync="isDefault"
              ></CSwitch>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('setAsTaxAddress') }}
            </td>
            <td>
              <CSwitch
                id="editAddressSetAsTax"
                color="success"
                v-model="isTaxAddress"
                :checked.sync="isTaxAddress"
              ></CSwitch>
            </td>
          </tr>
          <tr style="border-bottom: 1px solid #ECECEC;">
            <td>
              {{ $t('setAsShippingAddress') }}
            </td>
            <td>
              <CSwitch
                id="editAddressSetAsShipping"
                color="success"
                v-model="isDeliveryAddress"
                :checked.sync="isDeliveryAddress"
              ></CSwitch>
            </td>
          </tr>
        </table>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol>
        <CButton
          id="editAddressSaveButton"
          class="mt-4"
          color="success"
          block
          @click="savedata"
          v-if="loadingButton === false"
        >
          {{ $t('save') }}
        </CButton>
        <CButton id="editAddressSaveButtonDisabled" class="mt-4" color="success" block disabled v-else>
          <CSpinner color="white" size="sm"></CSpinner> {{ $t('save') }}
        </CButton>
      </CCol>
      <CCol>
        <router-link
          id="editAddressCancelLink"
          v-if="loadingButton === false"
          :to="`/member/${objectId}/address`"
          style="text-decoration:none"
        >
          <CButton id="editAddressCancelButton" class="mt-4" color="dark" block>{{ $t('cancel') }}</CButton>
        </router-link>
        <CButton id="editAddressCancelButtonDisabled" v-else class="mt-4" color="dark" block disabled>
          {{ $t('cancel') }}
        </CButton>
      </CCol>
    </CRow>
  </div>
  <div v-else id="editAddressNoPermission">
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import provinceutil from '@/util/province'
import json from '@/util/subdistrict.json'
import pos from '@/services/local'
import permis from '@/util/permission'

export default {
  data() {
    return {
      data: json,
      province: null,
      amphoe: null,
      tambon: null,
      provincedata: [],
      amphoedata: [],
      tambondata: [],
      isDefault: false,
      isDeliveryAddress: false,
      isTaxAddress: false,
      postalCode: '',
      taxId: '',
      phoneNumber: '',
      firstname: null,
      lastname: null,
      address: null,
      member: {},
      contactAlert: '',
      addressAlert: '',
      loadingButton: false,
      addressLists: [],
      name: ''
    }
  },
  computed: {
    ...mapGetters(['shops']),
    isPermission() {
      return permis.findPermissionRead('member', '/member/data')
    },
    isEditMember() {
      return permis.findPermissionEdit('member', '/member/data')
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    objectId() {
      return this.$route.params.objectId
    },
    addressObjectId() {
      return this.$route.params.addressObjectId
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    shop() {
      return this.shops.find((i) => i.objectId === this.shopObjectId)
    },
  },
  created() {
    this.provincedata = provinceutil.getProvince(this.data)
    this.getAddressByObjectId()
    this.getAddressByMemberObjectId()
  },
  methods: {
    validateContact() {
      if (!this.name || !this.phoneNumber) {
        this.contactAlert = 'กรุณากรอกข้อมูลให้ครบถ้วน'
        return false
      } else {
        if (!this.validatePhone(this.phoneNumber)) {
          this.contactAlert = this.$i18n.t('validateTel')
        } else {
          this.contactAlert = ''
          return true
        }
      }
    },
    validateAddress() {
      if (!this.address) {
        this.addressAlert = 'กรุณากรอกข้อมูลให้ครบถ้วน'
        return false
      } else {
        this.addressAlert = ''
        return true
      }
    },
    validatePhone(phone) {
      const MOBILEREG = /^[0]\d{9}$/
      return MOBILEREG.test(phone)
    },
    getAddressByMemberObjectId() {
      const objectId = this.objectId
      let url = '/api/v1.0/address/getbymember/' + objectId

      pos({
        method: 'get',
        url: url,
      })
        .then((res) => {
          this.addressLists = res.data.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getAddressByObjectId() {
      let url = '/api/v1.0/address/get/' + this.addressObjectId

      pos({
        method: 'get',
        url: url,
      })
        .then((res) => {
          let doc = res.data.data

          if (doc) {
            this.firstname = doc.firstname
            this.lastname = doc.lastname
            this.taxId = doc.taxId
            this.phoneNumber = doc.phoneNumber
            this.province = doc.province?.name || doc.province || '-'
            this.amphoe = doc.district?.name || doc.district || '-'
            this.tambon = (typeof doc.subDistrict === 'string' ? doc.subDistrict : doc.subDistrict?.name || '').trim() || '-'
            this.postalCode = doc.postalCode
            this.isDefault = doc.isDefault
            this.isTaxAddress = doc.isTaxAddress
            this.isDeliveryAddress = doc.isDeliveryAddress
            this.member = doc.member
            this.name = doc.name || `${doc.firstname || doc.firstName || ''} ${doc.lastname || doc.lastName || ''}`.trim() || '-';
            const houseNumber = doc.address || ''; // บ้านเลขที่, ซอย, ถนน
            const tambon = (typeof doc.subDistrict === 'string' ? doc.subDistrict : doc.subDistrict?.name) || '';
            const amphoe = doc.district?.name || doc.district || '';
            const province = doc.province?.name || doc.province || '';
            const postalCode = doc.postalCode || '';

            const fullAddressParts = [houseNumber, tambon, amphoe, province, postalCode];
            const filteredAddressParts = fullAddressParts.filter(part => part !== undefined && part.trim() !== '');

            const fullAddress = filteredAddressParts.join(', ');

            this.address = fullAddress || '-';
            this.getAmphoe()
            this.getTambon()
          } else {
            console.log('error')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getAmphoe() {
      this.tambondata = {}
      this.amphoedata = provinceutil.getAmphoe(this.data, this.province.id)
    },
    getTambon() {
      this.tambondata = provinceutil.getTambon(this.data, this.amphoe.id)
    },
    savedata() {
      let checkcontact = this.validateContact()
      let checkaddress = this.validateAddress()

      if (checkcontact && checkaddress) {
        this.loadingButton = true

        if (this.isDefault === true) {
          this.setDefaultAddress()
        }

        if (this.isDeliveryAddress === true) {
          this.setDeliveryAddress()
        }

        if (this.isTaxAddress === true) {
          this.setTaxAddress()
        }

        let data = {
          objectId: this.addressObjectId,
          firstname: this.firstname,
          lastname: this.lastname,
          taxId: this.taxId,
          phoneNumber: this.phoneNumber,
          // province: this.province,
          // district: this.amphoe,
          // subDistrict: this.tambon,
          // postalCode: this.postalCode,
          province: '',
          district: '',
          subDistrict: '',
          postalCode: '',
          address: this.address,
          isDefault: this.isDefault,
          isDeliveryAddress: this.isDeliveryAddress,
          isTaxAddress: this.isTaxAddress,
          member: this.member,
          shop: {
            id: this.shop.id,
            objectId: this.shop.objectId,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          uid: this.uid,
        }

        pos({
          method: 'post',
          url: '/api/v1.0/address/update',
          data: data,
        })
          .then((res) => {
            setTimeout(() => {
              this.loadingButton = false
              this.$router.push('/member/' + this.objectId + '/address')
            }, 1000)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    async setDefaultAddress() {
      let findIsDefault = this.addressLists.find((i) => i.isDefault === true)
      if (findIsDefault) {
        await this.updatedata({
          objectId: findIsDefault.objectId,
          isDefault: false,
        })
      }
    },
    async setDeliveryAddress() {
      let findIsDelivery = this.addressLists.find(
        (i) => i.isDeliveryAddress === true
      )
      if (findIsDelivery) {
        await this.updatedata({
          objectId: findIsDelivery.objectId,
          isDeliveryAddress: false,
        })
      }
    },
    async setTaxAddress() {
      let findIsTax = this.addressLists.find((i) => i.isTaxAddress === true)
      if (findIsTax) {
        await this.updatedata({
          objectId: findIsTax.objectId,
          isTaxAddress: false,
        })
      }
    },
    async updatedata(data) {
      pos({
        method: 'post',
        url: '/api/v1.0/address/update',
        data: data,
      })
        .then(() => {
          this.loadingButton = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
